import { OrderHistoryPickupTimeType } from '../../../redux/types';
import { format, formatHours, isToday, isTomorrow, isValid, utcToZonedTime } from '../dateTime';

const formatOrderDate = (date: Date, timezone: string, showPastWithTime?: boolean, pickupTimeType?: string) => {
    if (!isValid(new Date(date))) return '';
    const dateTimezoned = utcToZonedTime(date, timezone);
    const dateFormat = 'MMM dd, yyyy';
    const timeFormat = 'h:mm a';

    const asap = pickupTimeType === OrderHistoryPickupTimeType.ASAP;
    if (asap) return format(dateTimezoned, `${dateFormat} 'ASAP'`);

    const time = formatHours(dateTimezoned.toISOString(), timeFormat);

    if (isToday(dateTimezoned)) return `Today, ${time}`;
    if (isTomorrow(dateTimezoned)) return `Tomorrow, ${time}`;

    if (showPastWithTime) return format(dateTimezoned, `${dateFormat} 'at' ${timeFormat}`);

    return format(dateTimezoned, dateFormat);
};

export default formatOrderDate;
