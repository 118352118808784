import format from 'date-fns/format';

export const formatDate = (date: string, template = 'MM/dd/yyyy'): string => {
    return format(new Date(date), template);
};

export const formatHours = (date: string, template = 'h:mma'): string => {
    return format(new Date(date), template);
};

export * from 'date-fns';
export { utcToZonedTime, zonedTimeToUtc, format as formatTZ } from 'date-fns-tz';
