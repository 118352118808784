import React, { FC } from 'react';
import classnames from 'classnames';

import { IPhoneNumberLink } from '../../../@generated/@types/contentful';

import { formatPhoneNumber } from '../../../lib/formatPhoneNumber';
import Icon from '../BrandIcon';
import { PHONE_ICON_VARIANT } from './constants';
import styles from './phoneLink.module.css';

interface IInspirePhoneLinkProps {
    phone: IPhoneNumberLink | string;
    variant?: 'primary' | 'secondary';
    className?: string;
    iconClassName?: string;
}

const InspirePhoneLink: FC<IInspirePhoneLinkProps> = ({ phone, variant = 'primary', className, iconClassName }) => {
    let name = '';
    let phoneNumber = '';

    if (!phone) return null;

    if (typeof phone !== 'string') {
        if (!phone.fields) return null;

        name = phone.fields.name;
        phoneNumber = phone.fields.phoneNumber;
    } else {
        name = formatPhoneNumber(phone);
        phoneNumber = formatPhoneNumber(phone);
    }

    return (
        <a
            className={classnames('t-paragraph-small', styles.phoneLink, styles[variant], className)}
            href={`tel:${phoneNumber}`}
            aria-label={`Phone: ${name}`}
        >
            <Icon
                className={classnames(styles.phoneIcon, iconClassName)}
                icon="action-phone"
                variant={PHONE_ICON_VARIANT}
            />
            <span>{name}</span>
        </a>
    );
};

export default InspirePhoneLink;
