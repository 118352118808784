import { useBag } from '../../redux/hooks';
import {
    IOrderHistoryItemModifierGroupItemModel,
    IOrderHistoryItemModifierGroupItemModifierModel,
    IOrderModel,
    IOrderProductModel,
    ItemModifierModel,
    TallyProductModel,
} from '../../@generated/webExpApi';
import { getDiscountType, getTallyPriceType } from '../helpers/discountHelper';
import { useProducts } from '../../redux/hooks/domainMenu';
import { getDefaultNestedModifier, getModifierPrice, isModifierItemGroup } from '../../lib/domainProduct';
import { SHOULD_ADD_DEFAULT_MODIFIERS } from './useAddToBagFromOrderHistory.constants';

const useAddToBagFromOrderHistory = (order: IOrderModel) => {
    const bag = useBag();
    const domainProducts = useProducts();
    const { products, dateTime, fulfillment } = order;
    const location = fulfillment?.location;

    const modifierGroupsMap = (modifierGroups: IOrderHistoryItemModifierGroupItemModel[], productId?: string) => {
        if (SHOULD_ADD_DEFAULT_MODIFIERS) {
            const domainProduct = domainProducts[productId];
            const allModifierGroups =
                domainProduct?.itemModifierGroups?.map((mg) => ({
                    productId: mg.productGroupId,
                    modifiers: modifierGroups.find((nmg) => nmg.productId === mg.productGroupId)?.modifiers || [],
                })) || [];

            return allModifierGroups.map((modifierGroup) => {
                const domainModifierGroup = domainProduct?.itemModifierGroups?.find(
                    (m) => m.productGroupId === modifierGroup.productId
                );
                const modifiersIds = modifierGroup.modifiers.map((m) => m.productId);

                const hasDefaultQuantity = (item: ItemModifierModel) => {
                    if (!isModifierItemGroup(item)) {
                        return item.defaultQuantity > 0;
                    }

                    return Object.values(item.itemModifiers).find((modifier) => modifier.defaultQuantity > 0);
                };

                const defaultModifiersToAdd = Object.values(domainModifierGroup.itemModifiers || [])
                    ?.filter(hasDefaultQuantity)
                    ?.filter((m) => {
                        if (isModifierItemGroup(m)) {
                            const itemGroupIds = m.itemModifiers.map((m) => m.itemId);
                            return !modifiersIds.some((id) => itemGroupIds.includes(id));
                        } else {
                            return !modifiersIds.includes(m.itemId);
                        }
                    })
                    ?.map((modifier) => {
                        if (isModifierItemGroup(modifier)) {
                            const defaultNestedModifier = getDefaultNestedModifier(modifier.itemModifiers);

                            return {
                                itemGroupId: modifier.itemGroupId,
                                quantity: 1,
                                productId: defaultNestedModifier?.itemId,
                                price: getModifierPrice(defaultNestedModifier, products[defaultNestedModifier?.itemId]),
                            };
                        }

                        const productItemModifier = products[modifier.itemId];
                        const price = getModifierPrice(modifier, productItemModifier);

                        return {
                            productId: modifier.itemId,
                            quantity: modifier.defaultQuantity,
                            price,
                        };
                    });

                return {
                    productId: modifierGroup.productId,
                    modifiers: modifierGroup.modifiers.map(modifiersMap).concat(defaultModifiersToAdd),
                };
            });
        } else {
            return modifierGroups?.map((modifierGroup) => {
                return {
                    description: modifierGroup.description,
                    productId: modifierGroup.productId,
                    modifiers: modifierGroup.modifiers.map(modifiersMap),
                };
            });
        }
    };

    const modifiersMap = ({
        modifierActionCode: _modifierActionCode,
        modifierActionCodeName: _modifierActionCodeName,
        modifierGroups,
        ...modifier
    }: IOrderHistoryItemModifierGroupItemModifierModel) => ({
        ...modifier,
        modifierGroups: modifierGroupsMap(modifierGroups),
    });

    const getTallyItem = (product: IOrderProductModel, includeLineItemId: boolean): TallyProductModel => {
        const discountType = location
            ? getDiscountType(domainProducts[product.productId], location, dateTime)
            : undefined;
        const priceType = getTallyPriceType(discountType);

        const isCombo = !!product.childItems?.length;

        return {
            description: product.description,
            lineItemId: includeLineItemId ? product.lineItemId : undefined,
            modifierGroups: isCombo ? [] : modifierGroupsMap(product.modifierGroups, product.productId),
            price: product.price,
            priceType,
            productId: product.productId,
            quantity: product.quantity,
            childItems: isCombo ? product.childItems.map((c) => getTallyItem(c, includeLineItemId)) : undefined,
        };
    };

    const mapTallyItemWithLineItemId = (product: IOrderProductModel, _index?: number) => getTallyItem(product, true);
    const mapTallyItemWithoutLineItemId = (product: IOrderProductModel, _index?: number) =>
        getTallyItem(product, false);

    const addFromOrderHistory = () => {
        products.map(mapTallyItemWithoutLineItemId).forEach((pdpTallyItem) => {
            bag.actions.putToBag({
                pdpTallyItem,
            });
        });
    };

    return { addFromOrderHistory, mapTallyItem: mapTallyItemWithLineItemId };
};

export default useAddToBagFromOrderHistory;
