import React, { FC, Fragment } from 'react';
import classnames from 'classnames';

import { stringReplaceToArray } from '../../common/helpers/stringReplaceToArray';
import styles from './textWithTrademark.module.css';

export interface ITextWithTrademark extends React.HTMLAttributes<HTMLOrSVGElement> {
    tag: keyof JSX.IntrinsicElements;
    text: string;
    afterContent?: JSX.Element;
    dataTestId?: string;
}

const TextWithTrademark: FC<ITextWithTrademark> = (props) => {
    const { tag, text = '', className, afterContent, dataTestId, ...restProps } = props;

    // Replace every '®' with <sup>&reg;</sup>
    const formattedText = stringReplaceToArray(text, '®', <sup>&reg;</sup>).map((item, index) => (
        <Fragment key={index}>{item}</Fragment>
    ));

    if (afterContent) {
        formattedText.push(<Fragment key={formattedText.length}>{afterContent}</Fragment>);
    }

    const textClassName = classnames(styles.text, className);

    return React.createElement(
        tag,
        {
            ...restProps,
            'data-testid': dataTestId,
            className: textClassName,
        },
        formattedText
    );
};

export default TextWithTrademark;
