import React from 'react';
import classnames from 'classnames';

import styles from './index.module.css';

export interface IInfoBanner {
    title?: string;
    mainText?: string | JSX.Element; // JSX.Element - added only for auth0
    description?: string;
    className?: string;
    wrapperClassName?: string;
    childClassName?: string;
    titleClassName?: string;
    mainTextClassName?: string;
    descriptionClassName?: string;
    backgroundColor?: string;
    textColor?: string;
}

function InfoBanner(props: IInfoBanner): JSX.Element {
    const {
        title,
        mainText,
        description,
        className,
        wrapperClassName,
        titleClassName,
        mainTextClassName,
        descriptionClassName,
        backgroundColor,
        textColor,
    } = props;
    return (
        <div
            className={classnames(styles.wrapper, 'backgroundBanner', {
                [wrapperClassName]: !!wrapperClassName,
            })}
        >
            <div
                className={classnames(styles.banner, {
                    [className]: !!className,
                })}
            >
                {title && (
                    <h3
                        className={classnames('t-subheader', 'textColor', styles.title, {
                            [titleClassName]: !!titleClassName,
                        })}
                    >
                        {title}
                    </h3>
                )}
                {mainText && (
                    <h2
                        className={classnames('t-header-h1', 'textColor', styles.title, {
                            [mainTextClassName]: !!mainTextClassName,
                        })}
                    >
                        {mainText}
                    </h2>
                )}
                {description && (
                    <span
                        className={classnames(styles.description, 't-paragraph-hint', 'textColor', {
                            [descriptionClassName]: !!descriptionClassName,
                        })}
                    >
                        {description}
                    </span>
                )}
            </div>

            <style jsx>{`
                .backgroundBanner {
                    background-color: ${backgroundColor ? backgroundColor : 'var(--col--primary1)'};
                }
                .textColor {
                    color: ${textColor ? textColor : 'var(--col--light)'};
                }
            `}</style>
        </div>
    );
}

export default InfoBanner;
