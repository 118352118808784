import React, { ElementType } from 'react';
import classnames from 'classnames';
import Chip, { ChipTypeMap } from '@material-ui/core/Chip';

import styles from './chip.module.css';

interface IInspireChipProps {
    className?: string;
    component?: ElementType;
    onClick?: (e: React.MouseEvent) => void;
    selected?: boolean;
    dataTestId?: string;
}

/**
 * Chips represent complex entities in small blocks, such as a contact.
 * Demos:
 *
 * - [Chips](https://material-ui.com/components/chips/)
 *
 * API:
 *
 * - [Chip API](https://material-ui.com/api/chip/)
 */
const InspireChip = ({
    className,
    component,
    onClick,
    selected,
    dataTestId,
    ...props
}: IInspireChipProps & ChipTypeMap['props']): JSX.Element => {
    return (
        <Chip
            component={component}
            onClick={onClick}
            size="small"
            className={classnames(className, styles.chip, { [styles.selected]: selected })}
            classes={{
                label: styles.label,
                labelSmall: styles.labelSmall,
                sizeSmall: styles.sizeSmall,
                outlined: styles.outlined,
                clickable: styles.clickable,
            }}
            data-testid={dataTestId}
            {...props}
        />
    );
};

export default InspireChip;
