import React from 'react';
import { useRouter } from 'next/router';
import { useAppDispatch } from '../../../redux/store';
import classnames from 'classnames';
import Modal from '@material-ui/core/Modal';
import { InspireButton } from '../../atoms/button';
import styles from './haveAQuestionModal.module.css';
import { ILocationWithDetailsModel } from '../../../@generated/webExpApi';
import Icon from '../../../components/atoms/BrandIcon';
import { getLocationAddressString } from '../../../lib/locations';
import { formatPhoneNumber } from '../../../lib/formatPhoneNumber';
import { GTM_RECENT_ORDERS_CALL_STORE } from '../../../common/services/gtmService/constants';
import { useScreen } from '../../../common/hooks/useScreen';

export interface IWarningModalDescription {
    title: string;
    message?: string;
    textButton?: string;
}

interface IWarningModal {
    location: ILocationWithDetailsModel;
    isOpen: boolean;
    onModalClose?: () => void;
}

const HaveAQuestionModal = (props: IWarningModal) => {
    const { location, isOpen, onModalClose } = props;

    const { isDesktop } = useScreen();

    const dispatch = useAppDispatch();
    const router = useRouter();

    const handleClickCallButton = () => {
        router.replace('tel:${phone}');
        dispatch({
            type: GTM_RECENT_ORDERS_CALL_STORE,
            payload: { ctaName: 'Call Store', storeId: location.id },
        });
    };

    const formattedPhoneNumber = location?.contactDetails?.phone && formatPhoneNumber(location?.contactDetails?.phone);

    return (
        <Modal open={isOpen} onBackdropClick={onModalClose} className={styles.modal}>
            <div className={styles.wrapper}>
                <div className={styles.container}>
                    <Icon
                        icon="action-close"
                        className={styles.closeIcon}
                        onClick={onModalClose}
                        size={isDesktop ? 'm' : 's'}
                    />
                    <div className={classnames('t-subheader-small', styles.subHeader)}>Have a question?</div>
                    <h1 className={classnames('t-header-card-title', styles.header)}>Store Details</h1>
                    <div className={classnames('t-paragraph-strong', styles.name)}>{location?.displayName}</div>
                    <div className={classnames('t-paragraph', styles.address)}>
                        {getLocationAddressString(location)}
                    </div>
                    {formattedPhoneNumber && (
                        <div className={classnames('t-paragraph', styles.phone)}>
                            <Icon icon="action-phone" className={styles.phoneIcon} />
                            <span>{formattedPhoneNumber}</span>
                        </div>
                    )}
                    <Icon icon="animals-chicken" className={styles.chicken} size="huge" />
                </div>
                <InspireButton
                    className={styles.phoneButton}
                    fullWidth
                    text={`Call ${formattedPhoneNumber}`}
                    onClick={handleClickCallButton}
                />
            </div>
        </Modal>
    );
};

export default HaveAQuestionModal;
